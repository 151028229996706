import React from 'react'
import config from "../../../customize"

const Adsense = () => {
  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client={config.googleAdSenseId}
      data-ad-slot="8155311520"
      data-ad-format='auto'  // 自由
      data-full-width-responsive='true' // 自由
    />
  )
}

export default Adsense